<template>
  <main class="login">
    <h2>Přihlášení</h2>
    <form>
      <div class="error" v-if="error">
        <span>{{ error }}</span>
        <i class="fas fa-times fa-md" @click="error = ''"></i>
      </div>
      <input type="text" v-model="username" placeholder="Uživatelské Jméno" />
      <input type="password" v-model="password" placeholder="Heslo" @keyup.enter="login"/>
    </form>
    <button v-if="!loading" @click="login">Přihlásit</button>
    <button v-else class="loading">
      <i class="fas fa-circle-notch fa-spin"></i>
    </button>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    loading: false,
    error: "",
    username: "",
    password: "",
  }),
  computed: {
    online() {
      return this.$store.state.online;
    },
  },
  created() {
    if (this.$store.state.token) return this.$router.push("/");
  },
  methods: {
    async login() {
      if (!this.online) return this.error = "Jste offline. Zkuste to znovu po připojení.";
      this.loading = true;
      axios
        .post(this.$api("/login"), {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.data.success) {
            console.log(response.data);
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setToken", response.data.token);
            this.loading = false;
            this.$router.push("/");
          } else {
            this.error = "Nesprávné jméno nebo heslo";
            this.username = "";
            this.password = "";
            this.loading = false;
          }
        })
        .catch((err) => {
          this.error = "Nesprávné jméno nebo heslo";
          this.username = "";
          this.password = "";
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

main.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  h2 {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 400px;

    .error {
      display: flex;
      gap: 10px;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      background-color: $themeRed;
      border-radius: 5px;
      color: white;
    }


    input {
      all: unset;
      font-size: 0.8rem;
      width: 100%;
      max-width: 300px;
      box-sizing: border-box;
      padding: 0.5rem;
      background-color: #f5f5f5;
      border-radius: 5px;
    }
  }

  button {
    all: unset;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: $themeBlue;
    color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>