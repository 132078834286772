import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    online: true,
    user: null,
    token: null,
    lists: [],
    listModifications: [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      console.log("Setting token in store");
      state.token = token;
    },
    setLists(state, lists) {
      state.lists = lists
    },
    setOnline(state, online) {
      state.online = online
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('setToken', null);
      commit('setUser', null);
    },
    changeListItemStatus(store, { listId, itemId, status, value }) {
      let list = store.state.lists.find(list => list.id == listId);
      console.log(list);
      let item = list.items.find(item => item.id == itemId);
      item[status] = value;
      let listModification = store.state.listModifications.find(listModification => listModification.id== listId);
      if(listModification) {
        listModification.items.push({id: itemId, [status]: value});
      } else {
        listModification = {
          id: listId,
          items: [{id: itemId, [status]: value}]
        }
        store.state.listModifications.push(listModification);
      }
    },
    sendModifications(store) {
      if (store.state.online) {

        let listModifications = store.state.listModifications;
        let token = store.state.token;

        listModifications.forEach(list => {
          axios
            .put(
              Vue.prototype.$api("/lists/" + list.id + "/items"),
              {
                modifications: list.items,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((data) => {
              store.commit("setLists", data.data.lists);
            });
        });
      }
    },      
  },
  plugins: [
    new VuexPersist({
      key: 'windata',
      storage: window.localStorage,
      reducer: (state) => ({
        user: state.user,
        token: state.token,
        lists: state.lists,
        listModifications: state.listModifications
      })
    }).plugin,
  ],
})
