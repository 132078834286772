<template>
  <header>
    <h2>{{ title }}</h2>
    <router-link to="/items">
      <i class="fas fa-clipboard-list"></i>
    </router-link>
    <div class="profile" @click="logoutOverlay = true">
      {{ user.initials }}
    </div>
    <transition name="fade">
      <div class="modal-wrap" v-if="logoutOverlay">
        <div class="modal-clickout" @click="logoutOverlay = false"></div>
        <div class="modal">
          <h3>Chcete se odhlásit?</h3>
          <div class="modal-actions">
            <button class="red" @click="logoutOverlay = false">Zrušit</button>
            <button @click="logout">Odhlásit</button>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    logoutOverlay: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    title() {
      return this.$route.name;
    },
    r() {
      return this.$route.fullPath;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/variables.scss";

header {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 45px;

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    color: $themeDarkBlue;
    flex-grow: 1;
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-weight: 600;
    color: $themeBlue;
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .logout-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    all: unset;
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    i {
      font-size: 1.15rem;
      color: $themeDarkBlue;
    }
  }
}
</style>