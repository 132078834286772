import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Login from '../views/Login.vue'
import Seznam from '../views/Seznam.vue'
import SeznamCreate from '../views/SeznamCreate.vue'
import Items from '../views/Items.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Nákupní Seznamy',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/seznam/:id',
    name: 'Seznam',
    component: Seznam
  },
  {
    path: '/seznamy/novy',
    name: 'Vytvoření seznamu',
    component: SeznamCreate
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/items",
    name: "Položky",
    component: Items
  }
]

const router = new VueRouter({
  routes,
})

export default router
