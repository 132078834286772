<template>
  <div class="item-create modal-wrap" v-if="value">
    <div class="modal-clickout" @click="closeItemCreate"></div>
    <div class="modal">
      <div class="modal-title">Vytvoření položky</div>
      <div class="modal-input">
        <span class="input-label">Jméno položky</span>
        <input type="text" v-model="newItem.name" />
      </div>
      <div class="modal-input">
        <span class="input-label">Kategorie položky</span>
        <input type="text" v-model="newItem.category" />
      </div>
      <div class="input-row">
        <div class="modal-input">
          <span class="input-label">Výchozí množství</span>
          <input type="number" v-model="newItem.amount" />
        </div>
        <div class="modal-input">
          <span class="input-label">Jednotka</span>
          <input type="text" v-model="newItem.unit" placeholder="ks" />
        </div>
      </div>
      <div class="modal-input">
        <span class="input-label">O kolik snižovat/zvyšovat</span>
        <input type="number" v-model="newItem.step" />
      </div>
      <div class="modal-actions">
        <button class="full" @click="createItem">Vytvořit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    name: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(val) {
      if (val) this.newItem.name = this.name;
    },
  },
  data: () => ({
    newItem: {
      name: null,
      category: '',
      amount: 1,
      unit: 'ks',
      step: 1,
    },
  }),
  methods: {
    closeItemCreate() {
      this.$emit('input', false);
    },
    createItem() {
      if (!this.newItem.name || !this.newItem.category || !this.newItem.amount || !this.newItem.unit || !this.newItem.step) return;
      this.$emit('create', this.newItem);
      this.newItem = {
        name: null,
        category: '',
        amount: 0,
        unit: 'ks',
        step: 1,
      };
    },
  },
};
</script>

<style lang="scss">
.item-create {
  z-index: 50 !important;

  .modal-clickout {
    z-index: 51 !important;
  }

  .modal {
    z-index: 55 !important;
  }
}
</style>