<template>
  <main class="home">
    <Header></Header>
    <div class="lists">
      <router-link class="list create" to="/seznamy/novy">
        <i class="fas fa-plus"></i>
        <div class="list-title">Nový seznam</div>
      </router-link>
      <span class="subheader" v-if="listsForUser.length > 0">Pro mě</span>
      <router-link
        class="list"
        v-for="list in listsForUser"
        :to="`/seznam/${list.id}`"
        :key="list.id + '-forUser'"
      >
        <span class="list-title">{{ list.title }}</span>
        <span class="list-other">{{ list.date }} • od {{ list.author }}</span>
        <div class="fill"></div>
        <i class="fas fa-chevron-right"></i>
      </router-link>
      <span class="subheader" v-if="listsByUser.length > 0">Ode mě</span>
      <router-link
        class="list"
        v-for="list in listsByUser"
        :to="`/seznam/${list.id}`"
        :key="list.id + '-byUser'"
      >
        <span class="list-title">{{ list.title }}</span>
        <span class="list-other">{{ list.date }} • pro {{ list.target }}</span>
        <div class="fill"></div>
        <i class="fas fa-chevron-right"></i>
      </router-link>
      <span class="subheader" v-if="otherLists.length > 0"> Ostatní </span>
      <router-link
        class="list"
        v-for="list in otherLists"
        :to="`/seznam/${list.id}`"
        :key="list.id + '-other'"
      >
        <span class="list-title">{{ list.title }}</span>
        <span class="list-other"
          >{{ list.date }} • {{ list.author }} > {{ list.target }}</span
        >
        <div class="fill"></div>
        <i class="fas fa-chevron-right"></i>
      </router-link>
      <span class="subheader" v-if="listsDone.length > 0">Hotové</span>
      <router-link
        class="list done"
        v-for="list in listsDone"
        :to="`/seznam/${list.id}`"
        :key="list.id + '-done'"
      >
        <span class="list-title">{{ list.title }}</span>
        <span class="list-other"
          >{{ list.date }} • {{ list.author }} > {{ list.target }}</span
        >
        <div class="fill"></div>
        <i class="fas fa-chevron-right"></i>
      </router-link>
      <span v-show="lists.length == 0" class="subheader nothing"
        >Zatím tu nic není :)</span
      >
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
  computed: {
    lists() {
      return this.$store.state.lists;
    },
    user() {
      return this.$store.state.user;
    },
    listsForUser() {
      return this.lists.filter(
        (list) => list.target === this.user.username && list.done == false
      );
    },
    listsByUser() {
      return this.lists.filter(
        (list) => list.author === this.user.username && list.done == false
      );
    },
    otherLists() {
      return this.lists.filter(
        (list) =>
          list.author !== this.user.username &&
          list.target !== this.user.username &&
          list.done == false
      );
    },
    listsDone() {
      return this.lists.filter((list) => list.done);
    },
    online() {
      return this.$store.state.online;
    }
  },
  created() {
    if (this.online) {
      axios
        .get(this.$api("/lists"), {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((response) => {
          this.$store.commit("setLists", response.data.lists);
        });
    }
  },
  watch: {
    online(newValue) {
      if (newValue) {
        axios
          .get(this.$api("/lists"), {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          })
          .then((response) => {
            this.$store.commit("setLists", response.data.lists);
          });
      }
    }
  }
};
</script>


<style lang="scss">
@import "@/assets/variables.scss";

.lists {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .subheader {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 5px;

    &.nothing {
      color: #778ca3;
      text-align: center;
    }
  }

  .list {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #f5f5f5;

    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;

    .list-other {
      font-size: 0.8rem;
      color: #778ca3;
    }

    &.create {
      margin-bottom: 5px;
      background-color: $themeBlue;
      color: #fff;
      padding: 15px 20px;

      .list-title {
        font-weight: 500;
      }
    }

    &.done {
      background-color: #e5e5e5;
      color: #778ca3;
      padding: 15px 20px;

      .list-title {
        font-weight: 500;
      }
    }
  }
}
</style>