<template>
  <main class="list" v-show="list">
    <!-- ItemCreation -->
    <transition name="fade">
      <ItemCreate
        v-model="itemCreate"
        :name="newItemName"
        @create="createItem"
      ></ItemCreate>
    </transition>

    <!-- Search Add modal -->
    <transition name="fade">
      <div class="search-add-overlay" v-if="searchAdd">
        <div class="click-out" @click="searchAdd = null"></div>
        <div class="s-add">
          <div class="item-data">
            <span class="item-name">{{ searchAdd.name }}</span>
            <span class="item-cat">{{ searchAdd.category }}</span>
          </div>
          <div class="amount-picker">
            <div class="amount">
              <input type="text" v-model="searchAdd.amount" />
              <span>{{ searchAdd.unit }}</span>
            </div>
            <div class="buttons">
              <button @click="decrementAmount(searchAdd.step)">
                <i class="fas fa-minus"></i>
              </button>
              <button @click="incrementAmount(searchAdd.step)">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <button @click="addItem()">Přidat</button>
        </div>
      </div>
    </transition>

    <!-- Search Overlay -->
    <transition name="fade">
      <div class="search-overlay" v-if="openSearch" @click="openSearch = false">
        <!--<i class="fas fa-times"></i>-->
      </div>
    </transition>

    <header>
      <router-link class="back" to="/"
        ><i class="fas fa-chevron-left"></i
      ></router-link>
      <h2>{{ list.title }}</h2>
      <div
        class="add"
        :class="online ? '' : 'offline'"
        @click="deleteList"
        v-if="list.author === user.username"
      >
        <i class="fas fa-trash"></i>
      </div>
      <!--<router-link
        class="add"
        :to="`/seznam/${list.id}/pridat`"
        v-if="list.author === user.username"
        >Přidat</router-link
      >-->
    </header>
    <input
      type="text"
      class="adding"
      :class="online ? '' : 'offline'"
      :placeholder="addSearchItemPlaceholder"
      v-model="search"
      @input="(evt) => (search = evt.target.value)"
      @click="online ? (openSearch = true) : null"
    />
    <transition name="fade" mode="out-in">
      <div class="items" v-if="!openSearch" key="items">
        <span class="no-items" v-if="list.items.length == 0">
          Zatím tu nic není
        </span>
        <transition-group name="fade" tag="ul">
          <SwipeOut v-for="item in list.items" :key="item.id">
            <li class="item" @click="checkItem(item)">
              <i
                class="item-check far fa-circle"
                v-if="!item.checked && !item.oos"
              ></i>
              <i
                class="item-check fas fa-check-circle"
                v-else-if="item.checked && !item.oos"
              ></i>
              <i class="item-check fas fa-times-circle" v-else></i>
              <div
                class="item-name"
                :class="{ checked: item.checked, oos: item.oos }"
              >
                {{ item.name }}
              </div>
              <div class="item-amount">{{ item.amount }}{{ item.unit }}</div>

              <!--<div class="item-action" @click="deleteItem(item.id)">
              <i class="fas fa-trash"></i>
            </div>-->
            </li>
            <template v-slot:right="{ close }">
              <div class="swipeout-action delete" :class="{ 'disabled' : !online }" @click="deleteItem(item)">
                <i class="fas fa-trash"></i>
              </div>
              <div class="swipeout-action close" @click="close">
                <i class="fas fa-chevron-right"></i>
              </div>
            </template>
            <template v-slot:left="{ close }">
              <div class="swipeout-action close" @click="close">
                <i class="fas fa-chevron-left"></i>
              </div>
              <div
                class="swipeout-action oos"
                :class="{ checked: item.oos }"
                @click="outOfStock(item, close)"
              >
                <i
                  class="fas"
                  :class="{ 'fa-store-slash': !item.oos, 'fa-store': item.oos }"
                ></i>
              </div>
            </template>
          </SwipeOut>
        </transition-group>
      </div>
      <div class="searchTab" v-else key="search">
        <transition name="fade" mode="out-in">
          <div class="search-items" v-if="searchResult.length > 0">
            <div
              class="item"
              v-for="(res, index) in searchResult"
              :key="index"
              @click="searchAdd = res"
            >
              <div class="item-data">
                <span class="item-name">{{ res.name }}</span>
                <div class="item-cat">{{ res.category }} • {{ res.unit }}</div>
              </div>
              <button>Přidat</button>
            </div>
          </div>
          <div class="search-items" v-else-if="searchNothing">
            <div class="no-items">Žádné položky nenalezeny.</div>
            <button
              class="create-item"
              @click="
                itemCreate = true;
                newItemName = search;
              "
            >
              Vytvořit položku
            </button>
          </div>
          <template v-else-if="searchLoading">
            <div class="no-items">
              <i class="fas fa-circle-notch"></i>
            </div>
          </template>
        </transition>
      </div>
    </transition>
  </main>
</template>

<script>
import axios from "axios";
import ItemCreate from "../components/ItemCreate.vue";
import { SwipeOut } from "vue-swipe-actions";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
export default {
  components: {
    ItemCreate,
    SwipeOut,
  },
  data: () => ({
    search: "",
    previusSearch: "",
    blank: "",
    openSearch: false,
    searchResult: [],
    searchLoading: false,
    searchNothing: false,
    searchAdd: null,
    intervals: [],
    itemCreate: false,
    newItemName: "",
    itemModifications: [],
  }),
  computed: {
    list() {
      return this.$store.state.lists.find(
        (list) => list.id === this.$route.params.id
      );
    },
    user() {
      return this.$store.state.user;
    },
    online() {
      return this.$store.state.online;
    },
    addSearchItemPlaceholder() {
      return this.openSearch
        ? "Zadejte název položky"
        : "Klikněte zde pro přidání nové položky";
    },
  },
  created() {
    this.intervals.push(
      setInterval(() => {
        //console.log("Searching items");
        this.searchItems();
      }, 750)
    );
    if (this.online) {
      axios
        .get(this.$api("/lists"), {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then((response) => {
          this.$store.commit("setLists", response.data.lists);
        });
    }
    this.intervals.push(
      setInterval(() => {
        if (this.online) {
          axios
            .get(this.$api("/lists"), {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`,
              },
            })
            .then((response) => {
              this.$store.commit("setLists", response.data.lists);
            });
        }
      }, 5000)
    );
  },
  beforeDestroy() {
    this.intervals.forEach((interval) => clearInterval(interval));
  },
  methods: {
    deleteList() {
      if (!this.online) return;
      if (confirm("Opravdu chcete smazat seznam?")) {
        axios
          .delete(this.$api("/lists/" + this.list.id), {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            this.$router.push("/");
          });
      }
    },
    searchItems() {
      if (
        this.search.length > 0 &&
        this.openSearch &&
        this.search !== this.previusSearch &&
        this.search.length > 1 &&
        this.online
      ) {
        this.previusSearch = this.search;
        this.searchLoading = true;
        this.searchNothing = false;
        axios
          .get(this.$api("/search"), {
            params: {
              search: this.search,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            //Filter out items already in list
            this.searchResult = res.data.items.filter((item) => {
              return !this.list.items.find((i) => i.id === item.id);
            });
            this.searchNothing = this.searchResult.length == 0;
            this.searchLoading = false;
          });
      }
    },
    addItem() {
      axios
        .post(
          this.$api("/lists/" + this.list.id + "/items"),
          {
            id: this.searchAdd.id,
            amount: this.searchAdd.amount,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((data) => {
          this.$store.commit("setLists", data.data.lists);
          this.searchResult = this.searchResult.filter((item) => {
            return !this.list.items.find((i) => i.id === item.id);
          });
          this.searchAdd = null;
          this.openSearch = false;
        });
    },
    checkItem(item) {
      if (this.online) {
        axios
          .put(
            this.$api(
              "/lists/" + this.list.id + "/items/" + item.id + "/checked"
            ),
            {
              checked: !item.checked,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((data) => {
            this.$store.commit("setLists", data.data.lists);
          });
      } else {
        this.$store.dispatch("changeListItemStatus", {
          listId: this.list.id,
          itemId: item.id,
          status: "checked",
          value: !item.checked,
        });
      }
    },
    outOfStock(item, close) {
      if (this.online) {
        axios
          .put(
            this.$api("/lists/" + this.list.id + "/items/" + item.id + "/oos"),
            {
              oos: !item.oos,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((data) => {
            this.$store.commit("setLists", data.data.lists);
          });
      } else {
        this.$store.dispatch("changeListItemStatus", {
          listId: this.list.id,
          itemId: item.id,
          status: "oos",
          value: !item.oos,
        });
      }
      setTimeout(() => {
        close();
      }, 500);
    },
    incrementAmount(step) {
      this.searchAdd.amount = parseInt(this.searchAdd.amount) + step;
    },
    decrementAmount(step) {
      if (parseInt(this.searchAdd.amount) - step > 0) {
        this.searchAdd.amount = parseInt(this.searchAdd.amount) - step;
      }
    },
    createItem(data) {
      axios
        .post(this.$api("/items"), data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.previusSearch = "";
          this.searchItems();
          this.itemCreate = false;
        });
    },
    deleteItem(item) {
      if (this.online) {
        axios
          .delete(this.$api("/lists/" + this.list.id + "/items/" + item.id), {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((data) => {
            this.$store.commit("setLists", data.data.lists);
          });
      }
    },
    /*sendModifications() {
      if (this.online) {
        axios
          .put(
            this.$api("/lists/" + this.list.id + "/items"),
            {
              modifications: this.itemModifications,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((data) => {
            this.$store.commit("setLists", data.data.lists);
          });
      } else {
        //this.itemModifications = [];
      }
    },*/
  },
  watch: {
    openSearch() {
      if (!this.openSearch) {
        this.previusSearch = this.search;
        this.search = "";
      } else {
        this.search = this.previusSearch;
      }
    },
    online() {
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.list {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .offline {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }

  header {
    display: flex;
    align-items: center;
    height: 45px;

    h2 {
      font-size: 1.15rem;
      font-weight: bold;
      color: $themeBlue;
      flex-grow: 1;
    }

    .back {
      all: unset;

      i.fa-chevron-left {
        font-size: 1.15rem;
        font-weight: 700;
        padding-right: 20px;
        cursor: pointer;
        color: #1f1f1f;
      }
    }

    .add {
      all: unset;
      box-sizing: border-box;
      padding: 0 0.75rem;
      height: 35px;
      min-width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $themeBlue;
      //background-color: #fc5c65;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.85rem;
      line-height: 0.85rem;
      margin-left: 10px;
    }
  }

  .search-overlay {
    z-index: 30;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 25px;
    padding-top: 30px;

    i {
      box-sizing: border-box;
      padding: 0px 0.75rem;
      min-width: 42px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $themeBlue;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.85rem;
      line-height: 0.85rem;
      margin-left: 10px;
    }
  }

  .search-add-overlay {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .click-out {
      z-index: 51;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .s-add {
      z-index: 55;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      max-width: 80vw;
      background-color: #f5f5f5;
      border-radius: 5px;

      .item-data {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        font-size: 0.8rem;
        color: #1f1f1f;

        .item-name {
          font-weight: 500;
          color: $themeBlue;
        }

        .item-cat {
          font-weight: 400;
          color: #1f1f1f;
        }
      }
      .amount-picker {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .amount {
          display: flex;
          align-items: center;
          gap: 5px;
          input {
            all: unset;
            text-align: right;
            width: 40px;
            border-bottom: 1px solid $themeGray;
          }
          span {
            font-size: 0.8rem;
            color: $themeGray;
          }
        }

        .buttons {
          display: flex;
          gap: 10px;

          button {
            all: unset;
            background-color: $themeBlue;
            color: white;
            border-radius: 5px;
            font-size: 0.9rem;
            line-height: 1rem;
            padding: 7px 12px;
            cursor: pointer;
          }
        }
      }

      button {
        all: unset;
        background-color: $themeBlue;
        text-align: center;
        color: white;
        border-radius: 5px;
        font-size: 0.9rem;
        line-height: 1rem;
        padding: 7px 12px;
        cursor: pointer;
      }
    }
  }

  .adding {
    all: unset;
    z-index: 40;
    font-size: 0.8rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0.7rem 0.75rem;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .searchTab {
    z-index: 40;

    .search-items {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .item {
        z-index: 40;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: rgba($color: #f5f5f5, $alpha: 0.9);
        cursor: pointer;

        .item-data {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
          font-size: 0.8rem;
          color: #1f1f1f;

          .item-name {
            font-weight: 500;
            color: $themeBlue;
          }

          .item-cat {
            font-weight: 400;
            color: #1f1f1f;
          }
        }

        button {
          all: unset;
          font-size: 0.8rem;
          padding: 0.5rem 0.7rem;
          border-radius: 5px;

          background-color: $themeBlue;
          color: white;
        }
      }

      .no-items {
        text-align: center;
        font-size: 0.8rem;
        color: white;

        i {
          font-size: 1.75rem;
          color: white;
          animation: spin 1s linear infinite;
        }
      }

      .create-item {
        all: unset;
        position: absolute;
        bottom: 25px;
        align-self: center;
        font-size: 0.8rem;
        margin-top: 40px;
        padding: 0.5rem 0.7rem;
        border-radius: 5px;
        background-color: $themeBlue;
        color: white;
        cursor: pointer;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .no-items {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 0.8rem;
      color: #1f1f1f;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;

      .swipeout-left,
      .swipeout-right {
        .swipeout-action {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin: 0px 5px;

          font-size: 0.8rem;
          border-radius: 5px;
          padding: 0px 1rem;
          min-width: 58px;

          &.delete {
            color: white;
            background-color: $themeBlue;

            &.disabled {
              opacity: 0.5;
            }
          }

          &.oos {
            color: white;
            background-color: $themeRed;
          }

          &.oos.checked {
            color: white;
            background-color: $themeGreen;
          }

          &.close {
            min-width: unset;
            padding: 0px 0.5rem;
            color: #1f1f1f;
          }
        }

        &:not(.swipeout-right) {
          .swipeout-action:last-of-type {
            margin-right: 10px;
          }
          .swipeout-action:first-of-type {
            margin-left: 0px;
          }
        }

        &:not(.swipeout-left) {
          .swipeout-action:last-of-type {
            margin-right: 0px;
          }
          .swipeout-action:first-of-type {
            margin-left: 10px;
          }
        }
      }

      li {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: rgba($color: #f5f5f5, $alpha: 0.9);
        cursor: pointer;

        .item-check {
          font-size: 1rem;
          color: #1f1f1f;

          &.fa-check-circle {
            color: $themeBlue;
          }

          &.fa-times-circle {
            color: $themeRed;
          }
        }

        .item-amount {
          font-size: 0.8rem;
          color: $themeGray;
        }

        .item-name {
          font-size: 0.8rem;
          font-weight: 500;
          color: $themeBlue;
          flex-grow: 1;

          &.checked {
            text-decoration: line-through;
          }

          &.oos {
            color: $themeRed;
            text-decoration: line-through;
          }
        }
        .item-data {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
          font-size: 0.8rem;
          color: #1f1f1f;

          .item-cat {
            font-weight: 400;
            color: #1f1f1f;
          }
        }

        /*.item-actions {
          display: flex;
          align-items: center;

          .item-action {
            font-size: 0.8rem;
            padding: 0.5rem 0.7rem;
            border-radius: 5px;
            cursor: pointer;
            background-color: $themeBlue;
            color: white;

            &.add {
            }

            &.added {
              background-color: $themeGreen;
              color: white;
            }
          }
        }*/

        button {
          all: unset;
          font-size: 0.8rem;
          padding: 0.5rem 0.7rem;
          border-radius: 5px;

          &.add {
            background-color: $themeBlue;
            color: white;
            cursor: pointer;
          }

          &.added {
            background-color: $themeGreen;
            color: white;
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>