<template>
  <div id="body">
    <transition name="fade" mode="out-in">
      <section class="notify" v-if="!online || backOnline">
      <transition-group name="fade" mode="out-in">
        <article class="offline" v-if="!online && !onlineHide" key="offline">
          Jste offline
        </article>
        <article class="back" v-if="backOnline" key="back">
          Jste zpět online!
        </article>
      </transition-group>
    </section>
    </transition>
    <div>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    backOnline: false,
    onlineHide: false,
    intervals: [],
  }),
  computed: {
    title() {
      return this.$route.name;
    },
    user() {
      return this.$store.state.user;
    },
    online() {
      return this.$store.state.online;
    },
  },
  mounted() {
    this.$store.commit("setOnline", navigator.onLine);

    console.log(this.online);
    if (this.online) {
      console.log("Is online and getting user...");
      this.getUser();
    }

    window.addEventListener("online", () => {
      this.backOnlineFun();
    });

    window.addEventListener("offline", () => {
      console.log("Went offline");
      this.$store.commit("setOnline", false);
    });

    this.intervals.push(
      setInterval(() => {
        if (!this.online && navigator.onLine) this.backOnlineFun();
      }, 5000)
    );
  },
  beforeDestroy() {
    this.intervals.forEach((interval) => clearInterval(interval));
  },
  methods: {
    getUser() {
      if (this.$store.state.token && this.$route.fullPath !== "/login") {
        axios
          .get(this.$api("/user"), {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          })
          .then((res) => {
            this.$store.commit("setUser", res.data.user);
            return true;
          })
          .catch((err) => {
            console.log(err);
            alert(err);
            this.$store.commit("setToken", null);
            this.$store.commit("setUser", null);
            this.$store.commit("setLists", null);
            this.$router.push("/login");
            return false;
          });
      } else if (this.$route.fullPath !== "/login") {
        this.$router.push("/login");
        return null;
      }
    },
    backOnlineFun() {
      console.log("Is online and getting user...");
      this.getUser();
      this.$store.dispatch("sendModifications");
      setTimeout(() => {
        console.log("Got user and setting online...");
        this.$store.commit("setOnline", true);
        this.backOnline = true;
        setTimeout(() => {
          console.log("Setting backOnline to false...");
          this.backOnline = false;
        }, 3000);
      }, 1000);
    },
  },
  watch: {
    $route(to, from) {
      if (!this.$store.state.token && to.fullPath !== "/login") {
        return this.$router.push("/login");
      }
    },
  },
};
</script>

<style src="@/assets/reset.css" />

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "@/assets/variables.scss";

html {
  font-size: 18px;
}

html,
body,
#body {
  height: 100%;
  width: 100%;
}
#body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #010101;
  background-color: #d1d8e0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  section.notify {
    height: 52px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    padding: 5px;

    article {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.7rem 0.75rem;
      font-size: 0.9rem;
      border-radius: 5px;

      &.offline {
        background-color: $themeRed;
        color: black;
      }

      &.back {
        background-color: $themeGreen;
        color: black;
      }
    }
  }


  > div {
    box-sizing: border-box;
    padding: 25px;
    flex-grow: 1;
    transition: all 2s;
  }
}

.fill {
  flex-grow: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-clickout {
    z-index: 11;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  .modal {
    z-index: 12;
    width: 100%;
    max-width: 80%;

    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    background-color: #f5f5f5;

    .input-row {
      display: flex;
      gap: 10px;
    }

    .modal-input {
      display: flex;
      flex-direction: column;
      gap: 5px;

      input {
        all: unset;
        font-size: 0.8rem;
        width: 100%;
        max-width: 300px;
        box-sizing: border-box;
        padding: 0.2rem;
        border-bottom: 1px solid $themeGray;
        /*border-radius: 5px;
          background-color: rgba($color: $themeBlue, $alpha: 0.4);*/
      }

      .input-label {
        font-size: 0.8rem;
        color: $themeBlue;
      }
    }

    .modal-actions {
      display: flex;
      gap: 10px;
      width: 100%;
      justify-content: flex-end;

      button {
        all: unset;
        flex-grow: 1;
        flex-basis: 50%;
        max-width: 150px;
        text-align: center;
        font-size: 0.8rem;
        padding: 0.5rem;
        border-radius: 5px;
        background-color: $themeBlue;
        color: #fff;
        cursor: pointer;

        &.red {
          background-color: $themeRed;
        }

        &.full {
          max-width: 300px;
        }
      }
    }
  }
}
</style>
