<template>
  <main class="items-view">
    <!-- Edit Item Modal -->
    <transition name="fade">
      <div class="modal-wrap" v-if="item">
        <div class="modal-clickout" @click="item = null"></div>
        <div class="modal">
          <div class="modal-input">
            <span class="input-label">Jméno položky</span>
            <input type="text" v-model="item.name" />
          </div>
          <div class="modal-input">
            <span class="input-label">Kategorie položky</span>
            <input type="text" v-model="item.category" />
          </div>
          <div class="input-row">
            <div class="modal-input">
              <span class="input-label">Výchozí množství</span>
              <input type="number" v-model="item.amount" />
            </div>
            <div class="modal-input">
              <span class="input-label">Jednotka</span>
              <input type="text" v-model="item.unit" />
            </div>
          </div>
          <div class="modal-input">
            <span class="input-label">O kolik snižovat/zvyšovat</span>
            <input type="number" v-model="item.step" />
          </div>
          <div class="modal-actions">
            <button class="red" @click="itemDelete">Smazat</button>
            <button @click="saveItem">Uložit</button>
          </div>
        </div>
      </div>
    </transition>
    <!-- Add Item Modal -->
    <transition name="fade">
      <ItemCreate v-model="openCreation" @create="createItem"></ItemCreate>
    </transition>
    <header>
      <h2>Položky</h2>
      <router-link class="back" to="/">
        <i class="fas fa-times"></i>
      </router-link>
    </header>
    <transition-group class="items" name="fade">
      <div class="item create" key="create" @click="openCreation = true">
        <i class="fas fa-plus"></i>
        <span>Přidat položku</span>
      </div>
      <div class="item" v-for="i in items" :key="i.id" @click="editItem(i)">
        <div class="item-data">
          <div class="item-name">{{ i.name }}</div>
          <div class="item-cat">{{ i.category }} • {{ i.unit }}</div>
        </div>
        <i class="fas fa-pencil-alt"></i>
      </div>
    </transition-group>
  </main>
</template>

<script>
import axios from "axios";
import ItemCreate from "../components/ItemCreate.vue";
export default {
  components: {
    ItemCreate,
  },
  data() {
    return {
      items: [],
      openCreation: false,
      item: null,
      itemPreviousName: null,
    };
  },
  computed: {
    online() {
      if (this.$store.state.online) this.getItems();
      return this.$store.state.online;
    },
  },
  created() {
    if (this.$store.state.online) this.getItems();
  },
  methods: {
    getItems() {
      axios
        .get(this.$api("/items"), {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.items = response.data.items;
        });
    },
    editItem(item) {
      let i = item;
      this.item = i;
      this.itemPreviousName = i.name;
    },
    createItem(item) {
      axios
        .post(this.$api("/items"), item, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.items = response.data.items;
          this.openCreation = false;
        });
    },
    saveItem() {
      //Post edited item
      axios
        .put(this.$api("/items/" + this.item.id), this.item, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.items = response.data.items;
          this.item = null;
        });
    },
    itemDelete() {
      //Prompt user to confirm deletion
      if (confirm("Opravdu chcete smazat tuto položku?")) {
        axios
          .delete(this.$api("/items/" + this.item.id), {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.items = response.data.items;
            this.item = null;
          });
      }
    },
    resetNewItem() {
      this.newItem = {
        name: "",
        category: "",
        amount: 1,
        unit: "ks",
        step: 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.items-view {
  display: flex;
  flex-direction: column;
  gap: 30px;

  header {
    display: flex;
    align-items: center;
    height: 45px;

    h2 {
      font-size: 1.15rem;
      font-weight: bold;
      color: $themeBlue;
      flex-grow: 1;
    }

    .back {
      all: unset;
      margin-right: 5px;

      i {
        font-size: 1.4rem;
        font-weight: 700;
        cursor: pointer;
        color: #1f1f1f;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;

    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
      font-size: 0.9rem;

      background-color: #f5f5f5;
      border-radius: 5px;
      cursor: pointer;

      &.create {
        padding: 15px 20px;
        background: $themeBlue;
        color: #fff;
      }

      .item-data {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        font-size: 0.8rem;
        color: #1f1f1f;

        .item-name {
          font-weight: 500;
          color: $themeBlue;
        }

        .item-cat {
          font-weight: 400;
        }
      }
    }
  }
}
</style>