<template>
  <main class="list-create">
    <Header></Header>
    <div class="content">
      <input type="text" v-model="listName" placeholder="Název seznamu" />

      <span>Pro koho je tento seznam?</span>
      <div
        class="for-item null"
        :class="target == null ? 'active' : ''"
        @click="target = null"
      >
        <i :class="target == null ? 'fas fa-dot-circle' : 'far fa-circle'"></i>
        <span>Neurčeno</span>
      </div>
      <div
        class="for-item"
        :class="target == 'Michal' ? 'active' : ''"
        @click="target = 'Michal'"
      >
        <i
          :class="target == 'Michal' ? 'fas fa-dot-circle' : 'far fa-circle'"
        ></i>
        <span>Michal</span>
      </div>
      <!--do the same for Anička-->
      <div
        class="for-item"
        :class="target == 'Anička' ? 'active' : ''"
        @click="target = 'Anička'"
      >
        <i
          :class="target == 'Anička' ? 'fas fa-dot-circle' : 'far fa-circle'"
        ></i>
        <span>Anička</span>
      </div>
      <!--do the same for Táta-->
      <div
        class="for-item"
        :class="target == 'Táta' ? 'active' : ''"
        @click="target = 'Táta'"
      >
        <i
          :class="target == 'Táta' ? 'fas fa-dot-circle' : 'far fa-circle'"
        ></i>
        <span>Táta</span>
      </div>
      <div
        class="for-item"
        :class="target == 'Máma' ? 'active' : ''"
        @click="target = 'Máma'"
      >
        <i
          :class="target == 'Máma' ? 'fas fa-dot-circle' : 'far fa-circle'"
        ></i>
        <span>Máma</span>
      </div>

      <button @click="createList">Vytvořit seznam</button>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
export default {
  components: {
    Header,
  },
  data: () => ({
    listName: "",
    target: "Michal",
  }),
  methods: {
    createList() {
      if (this.listName) {
        axios
          .post(
            this.$api("/lists"),
            {
              title: this.listName,
              target: this.target,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`,
              },
            }
          )
          .then((response) => {
            this.$router.push(`/seznam/${response.data.list.id}`);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.content {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > span {
    font-size: 0.8rem;
    font-weight: 400;
  }

  input[type="text"] {
    all: unset;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0.7rem 0.75rem;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .for-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.75rem;
    background-color: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;

    span {
      font-size: 0.8rem;
    }

    &.active {
      i {
        color: $themeBlue;
      }

      span {
        color: $themeBlue;
      }
    }

    &.null {
      color: #778ca3;
    }
  }

  button {
    all: unset;
    background-color: $themeBlue;
    color: #fff;
    padding: 15px 20px;
    margin-top: 1rem;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
  }
}
</style>