import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$api = (url) => `https://api.michalwinter.cz/3978${url}`;


Vue.prototype.$getUser = () => {
  return new Promise((resolve, reject) => {
    if (store.state.token) {
      axios.get(Vue.prototype.$api('/user'), {
        headers: {
          'Authorization': `Bearer ${store.state.token}`
        }
      }).then((response) => {
        store.commit('setUser', response.data.user);
        store.commit('setLists', response.data.lists);
        resolve(response.data.user);
      }).catch((error) => {
        alert(error);
        localStorage.removeItem("token");
        store.commit('setUser', null);
        store.commit('setToken', null);
        store.commit('setLists', null);
        reject(error);
      });
    } else {
      reject();
    }
  });
};

/*router.beforeEach((to, from, next) => {
  Vue.prototype.$getUser().then(() => {
    next();
  }).catch((error) => {
    if (to.fullPath === '/login') return next();
    next('/login');
  });
});*/

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
